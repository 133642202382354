import React, { useCallback, useState } from 'react';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spacer } from '@intuitivo/outline';
import { Render, useToast } from '@intuitivo-pt/outline-ui';
import { saveAs } from 'file-saver';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectUserData, selectUserSpaceId, selectUserSchool } from 'actions/userActions';
import api from 'api';
import { CANCELED, ERROR } from 'constants/responseCodes';
import useApi from 'hooks/common/useApi';
import useFeature from 'hooks/common/useFeature';
import lang from 'lang';
import toggles from 'toggles';
import { readCSV } from 'utils';

import Button from 'components/common/Button';
import Input from 'components/common/Input';
import Loading from 'components/common/Loading';
import Modal from 'components/common/Modal';

import useStyles from './styles';

const IMPORT_FORM = 'IMPORT_FORM';
const IMPORTING = 'IMPORTING';
const DOWNLOAD_CREDENTIALS = 'DOWNLOAD_CREDENTIALS';

const ImportStudentsModal = ({ open, close, currentStudents, resetStudents }) => {
  const [files, setFiles] = useState([]);
  const schoolId = useSelector(selectUserSpaceId);
  const [createSchoolStudentsRequest] = useApi(api.createSchoolStudents);
  const toast = useToast();
  const iaveToggle = useFeature(toggles.iave);
  const classes = useStyles();
  const [importState, setImportState] = useState(IMPORT_FORM);
  const [credentialsCSVFile, setCredentialsCSVFile] = useState(null);
  const user = useSelector(selectUserData);
  const school = useSelector(selectUserSchool);

  const onDrop = (files) => {
    setFiles(files);
  };

  const close_ = () => {
    setTimeout(() => {
      setFiles([]);
      setCredentialsCSVFile(null);
      setImportState(IMPORT_FORM);
    }, 300);
    close();
  };

  const createCredentialsFile = useCallback((newUsers) => {
    let credentialsHeader = '';
    if (!iaveToggle) {
      credentialsHeader = `${lang.students.importStudents.fullName},${lang.students.importStudents.email},${lang.students.importStudents.password}\n`;
    }

    const credentialsCSV = newUsers.reduce((credentialsCSV, user) => {
      if (iaveToggle) {
        credentialsCSV += `${user.schoolIdentifier};${user.username};${user.password}\n`;
        return credentialsCSV;
      }

      credentialsCSV += `${user.fullName},${user.email},${user.password}\n`;
      return credentialsCSV;
    }, credentialsHeader);

    const csvFile = new Blob([credentialsCSV], { type: 'text/csv;charset=utf-8' });
    setCredentialsCSVFile(csvFile);
  }, [iaveToggle]);

  const confirm = useCallback(() => {
    const reader = new FileReader();

    reader.onload = () => {
      const text = reader.result;
      const studentsRaw = readCSV(text);

      /** parses csv row to student object */
      const parseStudentRaw = student => ({
        classes: student[2]
          ?.split('/')
          .map((_class) => _class?.trim())
          .filter((_class) => !!_class)
          ?? null,
        ...{ email: null, username: null, schoolIdentifier: null },
        ...(
          iaveToggle
            ? {
              schoolIdentifier: student[0]?.trim(),
              fullName: student[1]?.trim(),
              username: student[1]?.trim().toLowerCase(),
            }
            : {
              fullName: student[0]?.trim(),
              email: student[1]?.trim().toLowerCase(),
            }
        ),
      });

      const uniqueKey = iaveToggle ? 'username' : 'email';
      /** checks if students have unique key */
      const hasDuplicates = (students) =>
        (new Set(students.map(student => student[uniqueKey]))).size !== students.length;

      const students = studentsRaw.reduce(
        (acc, studentRaw) =>
          !studentRaw[0] ? acc : [...acc, parseStudentRaw(studentRaw)],
        [],
      );

      if (hasDuplicates(students)) {
        toast.error(lang.students.importStudents.duplicateError[uniqueKey]);
        return;
      }

      if (school.maxStudents && students.length + currentStudents.length > school.maxStudents) {
        const extraStudents = students.length + currentStudents.length - school.maxStudents;
        toast.error(lang.students.addStudents.totalStudentsSurpassedError(school.maxStudents, extraStudents));
        return;
      }

      const request = {
        params: { schoolId },
        data: { students },
      };

      setImportState(IMPORTING);
      createSchoolStudentsRequest(request, ({ data }) => {
        if (data.status === 0) {
          toast.success(lang.students.importStudents.successImport);
          resetStudents();
          createCredentialsFile(data.newUsers);
          setImportState(DOWNLOAD_CREDENTIALS);
        } else if (data.status === 3) {
          setImportState(IMPORT_FORM);
          toast.error(lang.students.importStudents.nonExistentClassesError);
        } else if (data.status !== ERROR && data.status !== CANCELED) {
          setImportState(IMPORT_FORM);
          toast.error(lang.oops);
        }
      });
    };

    reader.readAsText(files[0]);
  }, [files, iaveToggle, school, currentStudents, schoolId, createSchoolStudentsRequest, toast, resetStudents, createCredentialsFile]);

  const saveFile = () => {
    let fileName = 'credenciais.csv';
    if (iaveToggle) {
      fileName = `credenciais_${user.username}_${moment().format('YYMMDDHHmmss')}.csv`;
    }

    saveAs(credentialsCSVFile, fileName);
  };

  const actions = [
    {
      name: lang.cancel,
      onClick: close_,
      color: 'black',
      hide: importState !== IMPORT_FORM,
    },
    {
      name: lang.confirm,
      onClick: confirm,
      hide: importState !== IMPORT_FORM,
    },
  ];

  return (
    <Modal
      open={open}
      close={close_}
      header={lang.students.importStudents.header}
      actions={actions}
      center
      transition
      medium
    >
      <Render when={importState === IMPORT_FORM}>
        <Render when={iaveToggle}>
          <div>
            {lang.students.importStudents.descriptionIAVE}
          </div>
        </Render>
        <Render when={!iaveToggle}>
          <div>
            {lang.students.importStudents.description}
          </div>
          <div className={classes.downloadContainer}>
            <Button
              onClick={() => window.open(`${process.env.REACT_APP_S3_URL}/resources/estudantes.csv`, '_blank')}
              className={classes.downloadButton}
            >
              {lang.students.importStudents.exampleDownload}
            </Button>
          </div>
        </Render>
        <Spacer px={20} />
        <Input
          type="file"
          label={lang.students.importStudents.importStudentsLabel}
          placeholder={lang.students.importStudents.importStudentsPlaceholder}
          value={files}
          onDrop={onDrop}
          accept=".csv"
        />
        <Spacer px={20} />
      </Render>
      <Render when={importState === IMPORTING}>
        <Loading active />
      </Render>
      <Render when={importState === DOWNLOAD_CREDENTIALS}>
        <div className={classes.downloadContainer}>
          <FontAwesomeIcon
            icon={faCheckCircle}
            className={classes.importSuccessIcon}
          />
          <div>
            {lang.students.importStudents.importSuccessDescription}
          </div>
          <Button
            onClick={saveFile}
            className={classes.downloadButton}
          >
            {lang.download}
          </Button>
        </div>
      </Render>
    </Modal>
  );
};

ImportStudentsModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  currentStudents: PropTypes.array,
  resetStudents: PropTypes.func,
};

export default ImportStudentsModal;
